import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col text-center sm:text-left">
    <div className="mb-6">
      <Typography.SectionTitle>
        <Typography.SectionTitle className="inline-block">
          {localesText("for_shoppers.rewards")}
        </Typography.SectionTitle>

        <RotateHighlightText
          content={localesText("for_shoppers.instantly")}
          className="ml-2 md:ml-4"
        />
      </Typography.SectionTitle>
    </div>

    <div className="text-left">
      <div className="flex items-center gap-2">
        <div>01.</div>

        <Typography.Body className="text-clever-description ml-1">
          {localesText("for_shoppers.add_item")}
        </Typography.Body>
      </div>

      <div className="flex items-center gap-2">
        <div>02.</div>

        <Typography.Body className="text-clever-description">
          {localesText("for_shoppers.select_hc")}
        </Typography.Body>
      </div>

      <div className="flex items-end gap-2">
        <div>03.</div>

        <Typography.Body className="text-clever-description">
          {localesText("for_shoppers.receive_cashback")}
        </Typography.Body>
      </div>
    </div>
  </div>
);

export default memo(TitleComponent);
