import { memo } from "react";

import { NextLink } from "Components/Lib/Next";
import { Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { localesText } from "Utils/LocalesText.Util";
import { NormalImage } from "Components/Lib";

import FlowerMobile from "Assets/Images/Others/flower_mobile.png";

const MerchantDiscover = () => {
  return (
    <div className="relative flex w-full flex-col items-center">
      <div className="mb-8 mt-2">
        <NextLink href={PATHS_NAME.BUY_TO_EARN}>
          <Typography.Content className="text-center font-medium text-white underline underline-offset-[5px]">
            {localesText("for_shoppers.are_you_merchant")}
          </Typography.Content>
        </NextLink>
      </div>

      <NormalImage
        src={FlowerMobile.src}
        alt="flower-mobile"
        width={620}
        height={745}
      />
    </div>
  );
};

export default memo(MerchantDiscover);
