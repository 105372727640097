import { memo, useEffect, useState } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { getStoresCount } from "Services";
import { localesText } from "Utils/LocalesText.Util";
import { formatNumber } from "Utils/Number.Util";

import ShowMoreDeals from "./ShowMoreDeals.Component";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <Typography.SectionTitle>
          {localesText("common.earn")}{" "}
          <RotateHighlightText content={localesText("common.cashback")} />
        </Typography.SectionTitle>
        <Typography.SectionTitle>
          {localesText("for_shoppers.now_not_later")}
        </Typography.SectionTitle>
      </div>

      <div className="flex flex-col items-center gap-6 md:flex-row">
        <div className="flex flex-[2] flex-col gap-2">
          <Typography.Body className="text-clever-description">
            {localesText("for_shoppers.a_frictionless_checkout")}
          </Typography.Body>
          <Typography.Body className="text-clever-description">
            {localesText("for_shoppers.want_to_see_more")}{" "}
            <strong className="font-semibold text-clever-black">
              {formatNumber(storeCount)}+
            </strong>{" "}
            {localesText("for_shoppers.offers_in_our_app")}
          </Typography.Body>
        </div>
        <div className="flex w-full flex-1 items-center justify-start md:justify-end">
          <ShowMoreDeals buttonSize="large" />
        </div>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
