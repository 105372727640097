import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Tyro from "Assets/Images/Icons/tyro.png";

const TitleComponent = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10">
      <Typography.SectionTitle>
        {localesText("tyro.in_partnership")}
        <img
          className="inline w-[125px] h-[55px] md:w-[175px] md:h-[75px] ml-2"
          src={Tyro.src}
          alt="tyro"
        />
      </Typography.SectionTitle>
      <div className="flex-1 flex flex-col gap-3">
        <Typography.Content className="text-clever-description">
          {localesText("tyro.turn_new_customer")}
        </Typography.Content>
        <Typography.Content className="text-clever-description">
          {localesText("tyro.now_you_keep")}
        </Typography.Content>
        <Typography.Content className="text-clever-description">
          {localesText("tyro.clever_cashback_reward")}
        </Typography.Content>
      </div>
    </div>
  );
};

export default TitleComponent;
