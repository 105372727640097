import { useState, useEffect, useRef, memo } from "react";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  const [opacity, setOpacity] = useState<number>(1);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const targetElement = elementRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visibleRatio = entry.intersectionRatio;
            const calculatedOpacity = Math.max(0.3, visibleRatio);
            setOpacity(calculatedOpacity);
          } else {
            setOpacity(1);
          }
        });
      },
      {
        threshold: Array.from(Array(11).keys(), (i) => i * 0.1),
      },
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className="flex flex-col w-full gap-6 sm:gap-0 h-full justify-end items-center text-center transition-opacity duration-200"
      style={{ opacity }}
    >
      <div>
        <Typography.GiantTitle className="inline-block">
          {localesText("about.it_pays_to_be")}
        </Typography.GiantTitle>

        <div className="flex md:inline items-center justify-center">
          <img
            src="Assets/Svg/clever-logo-icon.svg"
            alt="icon"
            width={83}
            height={83}
            className="size-[56px] md:size-[83px] inline md:mb-8"
          />

          <Typography.GiantTitle className="inline">
            {localesText("about.clever")}
          </Typography.GiantTitle>
        </div>
      </div>

      <Typography.Body
        shouldScale={false}
        className="text-base md:text-lg text-center max-w-[872px] text-[#434A58]"
      >
        {localesText("about.hc_is_transforming")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
