import { Typography } from "Components/Own";

const Benefit = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    title: string;
    content: string;
  };
  itemWidth: number;
}) => {
  return (
    <div
      className="flex flex-col overflow-hidden h-fit max-w-[270px]"
      style={{ maxWidth: itemWidth - 64 }}
      suppressHydrationWarning={true}
    >
      <div className="mb-4 flex md:mb-6 justify-center w-full h-fit">
        <img
          src={item.image}
          width={50}
          height={50}
          className="w-[60px] aspect-square"
          alt="close-icon"
        />
      </div>

      <Typography.Heading2 className="text-clever-description text-center">
        {item.content}
      </Typography.Heading2>
    </div>
  );
};

export default Benefit;
