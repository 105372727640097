import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import ImageComponent from "./Image.Component";

const Section3 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-5",
      }}
    >
      <ImageComponent />
    </SectionWrap>
  );
};

export default memo(Section3);
