import { memo } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 lg:gap-6 min-h-[494px] w-full">
      <div className="flex flex-col gap-6 w-full">
        <img
          src="Assets/Svg/clever-blue.svg"
          alt="clever"
          width={60}
          height={60}
        />

        <div className="flex flex-col gap-6">
          <Typography.Heading1>
            {localesText("home.beyond")}
          </Typography.Heading1>

          <Typography.Description className="text-clever-gray">
            {localesText("home.we_go_beyond")}
          </Typography.Description>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-full lg:justify-center">
        <img
          src="Assets/Svg/money-yellow.svg"
          alt="money"
          width={60}
          height={60}
        />

        <div className="flex flex-col gap-6">
          <Typography.Heading1>
            {localesText("home.capture")}
          </Typography.Heading1>

          <Typography.Description className="text-clever-gray">
            {localesText("home.through_real_time")}
          </Typography.Description>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-full lg:justify-end">
        <img
          src="Assets/Svg/global-green.svg"
          alt="global"
          width={60}
          height={60}
        />

        <div className="flex flex-col gap-6">
          <Typography.Heading1>
            {localesText("home.go_global")}
          </Typography.Heading1>

          <Typography.Description className="text-clever-gray">
            {localesText("home.expand_business")}
          </Typography.Description>
        </div>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
