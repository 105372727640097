import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import ShopperButton from "../Section1/ShopperButton.Component";

const TitleComponent = () => {
  return (
    <div className="flex flex-col w-full items-center">
      <ShopperButton className="block min-[1600px]:hidden mt-3" />

      <div className="w-full flex flex-col xl:flex-row gap-8 py-[67px] md:py-[76px] min-[1600px]:mt-0">
        <div className="w-full h-full">
          <Typography.SectionTitle className="inline">
            {localesText("home.we_work_with")}
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline-block md:inline md:ml-2">
            {localesText("home.merchants_globally")}
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline-block md:inline md:ml-2">
            {localesText("home.to_deliver_money")}
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline md:ml-2">
            {localesText("home.back")}
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline w-full">
            <RotateHighlightText
              content={localesText("home.guaranteed")}
              className="ml-2"
            />
          </Typography.SectionTitle>
        </div>

        <Typography.Body className="w-full text-clever-gray-outer-space">
          {localesText("home.forget_points")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
