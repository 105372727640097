import { SectionWrap } from "Components/Layouts";

import SecurityComponent from "./Security.Component";

const Section5 = () => {
  return (
    <SectionWrap
      noSidePadding
      contentContainerProps={{
        className: "flex flex-col gap-10 md:gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <SecurityComponent />
    </SectionWrap>
  );
};

export default Section5;
