import { memo, useEffect, useRef, useState } from "react";
import { useIsInViewport } from "Hooks/useIsInViewPort";
import { NextLink } from "Components/Lib/Next";
import { Button, RotateHighlightText, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { HomeProps } from "Types";
import { localesText } from "Utils/LocalesText.Util";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";

const TitleComponent = ({
  referralBonus,
}: {
  referralBonus: HomeProps["referralBonus"];
}) => {
  const scrollRef = useRef<any>(null);
  const isInViewport = useIsInViewport(scrollRef);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("https://helloclever.page.link/RtQw");
    setCopySuccess(true);

    // Hide toast after 3 seconds
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const [firstScroll, setFirstScroll] = useState(true);

  useEffect(() => {
    if (isInViewport && firstScroll) {
      setFirstScroll(false);
      logAnalyticEvent({ key: TRACKING_EVENTS.referralViewed });
    }
  }, [firstScroll, isInViewport]);

  return (
    <>
      <div className="flex flex-1 flex-col justify-center items-center sm:items-start">
        <div className="mb-10 text-center md:text-left">
          <Typography.SectionTitle className="inline">
            <RotateHighlightText
              content={localesText("for_shoppers.friends")}
            />
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline ml-2">
            {localesText("for_shoppers.deserve_a_way")}
          </Typography.SectionTitle>
        </div>

        <Typography.Body className="mb-6 px-2 text-center text-clever-description sm:pr-4 md:px-0 md:pr-11 md:text-left">
          {localesText("for_shoppers.dont_keep_the_secret")}
        </Typography.Body>

        <Button
          className="rounded-full bg-clever-link px-7 w-fit md:mt-6"
          onClick={handleCopy}
        >
          {localesText("for_shoppers.share_with_friends")}
        </Button>
      </div>

      {copySuccess && (
        <div className="toast toast-top toast-center z-[1000]">
          <div className="alert alert-success flex items-center gap-2 w-full min-w-[300px] text-center">
            <span>Link copied successfully!</span>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(TitleComponent);
