import { memo } from "react";

import { LottieImage } from "Components/Own";

import PaymentRequestBanner from "Assets/Lotties/payment_request_banner.json";

function ImageComponent() {
  return (
    <div className="relative flex">
      <div className="max-w-[1000px]">
        <LottieImage options={{ animationData: PaymentRequestBanner }} />
      </div>
    </div>
  );
}

export default memo(ImageComponent);
