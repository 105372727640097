import { NormalImage } from "Components/Lib";
import ImgGroup from "Assets/Images/Others/about_us_group_three.png";

const ImageGroup = () => {
  return (
    <NormalImage
      src={ImgGroup.src}
      alt="img"
      width={200}
      height={120}
      className="absolute xl:right-80 -top-10"
    />
  );
};

export default ImageGroup;
