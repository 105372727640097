import Cashback from "Assets/Lotties/home_cashback.json";
import {
  AppDownLoadButton,
  RotateHighlightText,
  Typography,
} from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo } from "react";
import { localesText } from "Utils/LocalesText.Util";

const BuyToEarn = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 w-full items-center">
      <div className="flex flex-1 flex-col gap-6 items-start">
        <div className="text-left">
          <Typography.SectionTitle className="inline">
            {localesText("home.give_your_customers")}
          </Typography.SectionTitle>

          <Typography.SectionTitle>
            <RotateHighlightText
              content={localesText("home.instant_cashback")}
              className="mr-2 my-2 inline-block"
            />
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline">
            {localesText("home.at_checkout_with")}
          </Typography.SectionTitle>

          <Typography.SectionTitle className="inline xl:block ml-2 xl:ml-0">
            {localesText("home.hello_clever")}
          </Typography.SectionTitle>
        </div>

        <div className="lg:pr-11 text-clever-gray">
          <Typography.Body>{localesText("home.launch_with")}</Typography.Body>
        </div>

        <AppDownLoadButton
          theme="dark"
          showSeperate
          className="m-auto my-4 xl:m-0"
        />
      </div>

      <div className="max-w-[600px]">
        <LottieImage options={{ animationData: Cashback }} />
      </div>
    </div>
  );
};

export default memo(BuyToEarn);
