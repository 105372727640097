import { memo } from "react";

import { SectionWrap, SlateWhite } from "Components/Layouts";
import { LottieImage } from "Components/Own";

import SaveSpend from "Assets/Lotties/save_spend.json";

import FirstTitle from "./FirstTitle.Component";

const Section3 = () => {
  return (
    <SectionWrap
      containerProps={{ className: "my-8" }}
      contentContainerProps={{
        className: "flex flex-col gap-5 flex-1",
      }}
      className="flex items-center justify-center"
    >
      <SlateWhite
        containerClassName="px-3 md:px-8 py-12"
        contentContainerClassName="flex flex-col items-center lg:flex-row flex-1"
        firstComponent={<FirstTitle />}
        secondComponent={
          <div className="flex justify-center h-full max-h-[540px] flex-1">
            <LottieImage options={{ animationData: SaveSpend }} />
          </div>
        }
      />
    </SectionWrap>
  );
};

export default memo(Section3);
