import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

import dynamic from "next/dynamic";
import { SayHelloButton } from "./SayHelloButton.Component";

const PriceOverview = dynamic(() => import("./PriceOverview.Component"), {
  ssr: false,
});

const Section1 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "bg-clever-primary",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center pt-8",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <SayHelloButton />
      <PriceOverview />
    </SectionWrap>
  );
};

export default Section1;
