import { memo, useEffect, useState } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { getStoresCount } from "Services";
import { formatNumber } from "Utils/Number.Util";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="mb-8 flex flex-col gap-6 items-center max-w-[1096px] text-white text-center">
      <Typography.Giant className="max-w-[950px] text-center text-white">
        {localesText("home.checkout_the_different")}
      </Typography.Giant>

      <div>
        <Typography.Body className="inline lg:inline-block mr-1">
          {localesText("home.all_in_one")}
        </Typography.Body>

        <Typography.Body className="inline lg:inline-block">
          {localesText("home.download")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
