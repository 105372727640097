import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          <div className="max-w-[469px]">
            {localesText("payment_request.streamlined")}

            <RotateHighlightText
              content={localesText("payment_request.management")}
              className="mx-1 inline-block"
            />
          </div>

          {localesText("payment_request.at_your_fingertips")}
        </Typography.SectionTitle>
      </div>

      <div className="sm:pr-4 md:pr-11 text-clever-gray">
        <Typography.Body>
          {localesText("payment_request.effortlessly")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
