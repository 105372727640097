import { NormalImage } from "Components/Lib";
import ImgGroup from "Assets/Images/Others/about_us_billard.png";
import { useEffect, useState } from "react";

const ImageGroup2 = () => {
  const [offset, setOffset] = useState(0);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = Math.max(0, window.scrollY);

      if (currentScrollTop > lastScrollTop) {
        setOffset(isMobile ? 50 : 200);
      } else if (currentScrollTop < lastScrollTop) {
        setOffset(0);
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  return (
    <NormalImage
      style={{ transform: `translateY(${offset}px)` }}
      src={ImgGroup.src}
      alt="img"
      width={312}
      height={184}
      className="absolute right-0 sm:right-16 xl:right-32 -bottom-12 sm:bottom-5 xl:bottom-14 transition-transform duration-500 w-[164px] h-[97px] md:w-[312px] md:h-[184px]"
    />
  );
};

export default ImageGroup2;
