import dynamic from "next/dynamic";

import Home from "./Home";
import Blogs from "./Blogs";
import BlogDetail from "./BlogDetail";
import Industries from "./Industries";
import Shops from "./Shops";
import ShopDetail from "./ShopDetail";
import CaseStudies from "./CaseStudies";
import AboutUs from "./AboutUs";
import ECommerce from "./ECommerce";
import PayTo from "./PayTo";
import Xero from "./Xero";
import TyroMerchant from "./TyroMerchant";
import PaymentRequest from "./PaymentRequest";
import InStore from "./InStore";
import ForShoppers from "./ForShoppers";

const GetInTouch = dynamic(() => import("./GetInTouch"), { ssr: true });
const SelectBanks = dynamic(() => import("./SelectBanks"), { ssr: false });
const NotFound = dynamic(() => import("./404"), { ssr: false });
const InternalServer = dynamic(() => import("./500"), { ssr: false });
const Terms = dynamic(() => import("./Terms"), { ssr: false });
const Policy = dynamic(() => import("./Policy"), { ssr: false });

export {
  Home,
  InternalServer,
  NotFound,
  AboutUs,
  ECommerce,
  CaseStudies,
  GetInTouch,
  Blogs,
  BlogDetail,
  Industries,
  Shops,
  ShopDetail,
  Terms,
  Policy,
  SelectBanks,
  PayTo,
  Xero,
  TyroMerchant,
  PaymentRequest,
  InStore,
  ForShoppers,
};
