import PayOutAnimate from "Assets/Lotties/home_pay_out.json";
import { RotateHighlightText, Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo } from "react";
import { localesText } from "Utils/LocalesText.Util";

const PayOut = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 items-center">
      <div className="flex flex-1 flex-col gap-6 justify-center">
        <div className="text-left">
          <Typography.SectionTitle>
            <div className="max-w-[564px]">
              <RotateHighlightText
                content={localesText("home.pay_out")}
                className="mr-2"
              />

              {localesText("home.for")}
            </div>
          </Typography.SectionTitle>

          <Typography.SectionTitle>
            {localesText("home.global_merchants")}
          </Typography.SectionTitle>
        </div>

        <div className="lg:pr-11 text-clever-gray">
          <Typography.Body>
            {localesText("home.leverage_our_global")}
          </Typography.Body>
        </div>
      </div>

      <div className="max-w-[600px]">
        <LottieImage options={{ animationData: PayOutAnimate }} />
      </div>
    </div>
  );
};

export default memo(PayOut);
