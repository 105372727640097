import { SubscribeEmail } from "Components/Own";

import Section1 from "./Section1";
import Section2 from "./Section2";

const Blogs = ({ blogs }: { blogs: any[] }) => {
  
  return (
    <div>
      <Section1 data={!!blogs && !!blogs[0] ? blogs[0] : {}} />
      {/* Will add filter by category here */}
      {!!blogs && blogs.length >= 2 && (
        <Section2 blogs={blogs.filter((_blog, index) => index >= 1)} />
      )}
      <SubscribeEmail />
    </div>
  );
};

export default Blogs;
