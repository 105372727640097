import { useEffect } from "react";

import { BlogSection, VentureSection } from "Components/Own";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { HomeProps } from "Types";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { checkOS } from "Utils/Common.Util";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";

const HomePage = ({ blogs }: HomeProps) => {
  useEffect(() => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.homePageLanded,
      params: { OS: checkOS() },
    });
  }, []);

  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <BlogSection blogs={blogs} />
      <VentureSection />
    </div>
  );
};

export default HomePage;
