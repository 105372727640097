import { memo } from "react";

import { SlateWhite } from "Components/Layouts";
import { RotateHighlightText, Typography } from "Components/Own";
import ImageComponent from "./Image.Component";
import { localesText } from "Utils/LocalesText.Util";

const SecurityComponent = () => {
  return (
    <SlateWhite
      containerClassName="px-4 md:px-8 py-10 !bg-clever-gray-light w-[calc(100vw-16px)] md:w-[calc(100vw-40px)]"
      firstComponent={
        <div className="flex flex-col-reverse md:flex-row text-center md:text-left gap-[69px] w-full justify-between items-center">
          <ImageComponent />

          <div className="flex flex-1 flex-col justify-center">
            <Typography.Heading1>
              <div className="flex flex-col items-center md:items-start">
                <RotateHighlightText
                  content={localesText("payment_request.security")}
                  className="inline-block w-fit"
                />

                {localesText("payment_request.you_can_rely_on")}
              </div>
            </Typography.Heading1>

            <div className="text-clever-gray">
              <Typography.Body>
                {localesText("payment_request.feel_confident")}
              </Typography.Body>
            </div>
          </div>
        </div>
      }
      secondComponent={null}
    />
  );
};

export default memo(SecurityComponent);
