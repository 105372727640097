import { SectionWrap } from "Components/Layouts";
import { InstantDealProps } from "Types";

import TitleComponent from "./Title.Component";

const Section2 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "min-[1600px]:mt-[120px] flex w-full flex-col",
      }}
    >
      <TitleComponent />
    </SectionWrap>
  );
};

export default Section2;
