import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section3 = () => {
  return (
    <SectionWrap className="mt-28 md:mt-56 md:mx-20">
      <TitleComponent
        paragraph="At {{logo}} Hello Clever, our team is the heart of everything we do. We are a {{groupImage}} group
      of passionate, innovative, and forward-thinking individuals committed to
      transforming the payments landscape. {{moneyIcon}} Our diverse backgrounds bring a
      unique blend of creativity and expertise, enabling us to design solutions
      that are not only cutting-edge but also highly impactful for businesses
      and consumers {{alike}}"
      />
    </SectionWrap>
  );
};

export default memo(Section3);
