import { memo } from "react";

import AboutUsFirst from "Assets/Images/Others/about_us_first.webp";
import AboutUsFourth from "Assets/Images/Others/about_us_fourth.webp";
import AboutUsSecond from "Assets/Images/Others/about_us_second.webp";
import AboutUsThird from "Assets/Images/Others/about_us_third.webp";
import { NormalImage } from "Components/Lib";
import { NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { CAREERS } from "Constants/URL.Constant";
import Marquee from "react-fast-marquee";

const ImageComponent = () => {
  const imageGroups = [
    {
      className: "flex items-center gap-6",
      images: [
        {
          src: AboutUsSecond.src,
          width: 500,
          height: 500,
          className:
            "size-[343px] lg:w-[500px] lg:h-[500px] rounded-[28px] object-cover",
        },
        {
          src: AboutUsThird.src,
          width: 320,
          height: 320,
          className:
            "w-[220px] lg:w-[320px] lg:h-[320px] rounded-[28px] object-cover",
        },
      ],
    },
    {
      className: "flex items-end gap-6",
      images: [
        {
          src: AboutUsFirst.src,
          width: 480,
          height: 320,
          className:
            "w-[320px] h-[160px] lg:w-[480px] lg:h-[320px] rounded-[28px] object-cover",
        },
        {
          src: AboutUsFourth.src,
          width: 780,
          height: 500,
          className:
            "w-[460px] h-[343px] lg:w-[780px] lg:h-[500px] rounded-[28px] object-cover",
        },
      ],
    },
  ];

  return (
    <div className="relative overflow-hidden">
      <Marquee speed={80} className="w-screen">
        <div className="flex gap-6 mx-3">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={`image-${index}`}
              className={imageGroups[index % imageGroups.length].className}
            >
              {imageGroups[index % imageGroups.length].images.map(
                (image, index) => (
                  <NormalImage
                    key={`image-${index}`}
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    className={image.className}
                  />
                ),
              )}
            </div>
          ))}
        </div>
      </Marquee>

      <div className="hidden lg:flex flex-col items-center">
        <NextLink
          target="_blank"
          href={CAREERS}
          className="absolute bottom-52 animate-bounce z-10"
        >
          <Button className="rounded-full flex items-center h-full bg-clever-link px-7 py-5 w-fit">
            <img
              src="Assets/Svg/clever-icon-standard.svg"
              alt="icon"
              className="mr-2 w-8 h-8"
            />
            <span className="text-2xl text-[#F9FAFF]">Work with us</span>
          </Button>
        </NextLink>
      </div>

      <div className="flex lg:hidden flex-col items-center mt-8 w-full">
        <NextLink
          target="_blank"
          href={CAREERS}
          className="w-full min-[500px]:w-[314px] px-4 animate-bounce"
        >
          <Button className="rounded-full bg-clever-link border-none h-14 w-full min-w-[314px] flex items-center justify-center text-[#F9FAFF]">
            <img
              src="Assets/Svg/clever-icon-standard.svg"
              alt="icon"
              className="mr-2 w-6 h-6"
            />
            Work with us
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default memo(ImageComponent);
