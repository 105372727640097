import APIResponse from "Assets/Lotties/home_api_response.json";
import { RotateHighlightText, Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo } from "react";
import { localesText } from "Utils/LocalesText.Util";

const LaunchFaster = () => {
  return (
    <div className="flex flex-col-reverse xl:flex-row gap-10 md:gap-16 items-center pt-[84px] pb-[57px] md:py-0">
      <div className="max-w-[600px]">
        <LottieImage options={{ animationData: APIResponse }} />
      </div>

      <div className="flex flex-1 flex-col gap-6 justify-center">
        <div className="text-left">
          <Typography.SectionTitle>
            <span className="mr-2">{localesText("home.launch_faster")}</span>

            <RotateHighlightText
              content={localesText("home.apis")}
              className="inline-block"
            />
          </Typography.SectionTitle>
        </div>

        <div className="sm:pr-4 md:pr-11 text-clever-gray-outer-space">
          <Typography.Body>
            {localesText("home.ready_to_get_started")}
          </Typography.Body>
        </div>
      </div>
    </div>
  );
};

export default memo(LaunchFaster);
