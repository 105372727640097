import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Benefit from "./Benefit.Component";

export default function BenefitsList() {
  const data = [
    {
      id: 1,
      image: "Assets/Svg/lightning_blue.svg",
      content: localesText("payment_request.instant_payments"),
    },
    {
      id: 2,
      image: "Assets/Svg/payment_request_yellow.svg",
      content: localesText("payment_request.simple_payment_request"),
    },
    {
      id: 3,
      image: "Assets/Svg/minimise_payment_green.svg",
      alt: "benefit",
      content: localesText("payment_request.minimise_missed_payments"),
    },
    {
      id: 3,
      image: "Assets/Svg/boost_yellow.svg",
      alt: "benefit",
      content: localesText("payment_request.boost_cash_flow"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={Benefit}
      itemSize={440}
      fillNumber={0}
      shoulScale={false}
      contentContainerClassName="gap-y-6"
    />
  );
}
