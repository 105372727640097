import { memo } from "react";

import LottieImageContainer from "./LottieImage.Container";
import LottieImageV2Container from "./LottieImageV2.Container";

const LottieImage = ({ isV2 = false, ...rest }: any) => {
  if (isV2) {
    return <LottieImageV2Container {...rest} />;
  }

  return <LottieImageContainer {...rest} />;
};

export default memo(LottieImage);
