import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import Work from "./Work.Component";

const Section6 = () => {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      contentContainerProps={{
        className:
          "w-full flex flex-col gap-10 md:gap-12 justify-center items-center",
      }}
    >
      <TitleComponent />
      <Work />
    </SectionWrap>
  );
};

export default Section6;
