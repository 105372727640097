import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import ImageGroup from "./ImageGroup.Component";
import ImageList from "./ImageList.Component";
import ImageSingle from "./ImageSingle.Component";
import TitleComponent from "./Title.Component";

const Section1 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "relative",
      }}
      contentContainerProps={{
        className:
          "flex w-full h-full flex-col items-end justify-end lg:flex-row lg:items-start",
      }}
      className="flex items-center justify-center h-full mt-[100px]"
    >
      <ImageList />
      <ImageGroup />
      <ImageSingle />
      <TitleComponent />
    </SectionWrap>
  );
};

export default memo(Section1);
