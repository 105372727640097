import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section3 = () => {
  return (
    <SectionWrap className="pt-[62px] md:pt-[124px]">
      <TitleComponent />
    </SectionWrap>
  );
};

export default memo(Section3);
