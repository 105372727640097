import { memo } from "react";

import { LottieImage } from "Components/Own";

import PaymentRequestSecurity from "Assets/Lotties/payment_request_security.json";

const ImageComponent = () => (
  <div className="flex flex-1 w-full items-center justify-center ">
    <div className="relative w-full max-w-[540px]">
      <LottieImage options={{ animationData: PaymentRequestSecurity }} />
    </div>
  </div>
);

export default memo(ImageComponent);
