import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section5 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-6",
      }}
    >
      <TitleComponent />
    </SectionWrap>
  );
};

export default Section5;
