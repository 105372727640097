import { SectionWrap } from "Components/Layouts";
import { HomeProps } from "Types";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Section4 = ({
  referralBonus,
}: {
  referralBonus: HomeProps["referralBonus"];
}) => {
  return (
    <SectionWrap
      containerProps={{
        className: "py-12",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-6 lg:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <ImageComponent />
      <TitleComponent referralBonus={referralBonus} />
    </SectionWrap>
  );
};

export default Section4;
