import { useEffect } from "react";

import { BlogSection, VentureSection } from "Components/Own";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { HomeProps } from "Types";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { checkOS } from "Utils/Common.Util";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const ForShoppersPage = ({ instantDeals, referralBonus, blogs }: HomeProps) => {
  useEffect(() => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.homePageLanded,
      params: { OS: checkOS() },
    });
  }, []);

  return (
    <div>
      <Section1 />
      <Section2 instantDeals={instantDeals} />
      <Section3 />
      <Section4 referralBonus={referralBonus} />
      <BlogSection blogs={blogs} />
      <VentureSection />
    </div>
  );
};

export default ForShoppersPage;
