import { memo } from "react";

import { LottieImage } from "Components/Own";

import RewardRegulars from "Assets/Lotties/reward_regulars.json";

function ImageComponent() {
  return (
    <div className="relative flex">
      <LottieImage options={{ animationData: RewardRegulars }} />
    </div>
  );
}

export default memo(ImageComponent);
