import { memo } from "react";

import { LottieImage } from "Components/Own";

import PaymentRequestCustomerOne from "Assets/Lotties/payment_request_customer_one.json";

const ImageComponent = () => (
  <div className="flex flex-1 justify-center md:justify-start">
    <div className="relative w-full max-w-[530px]">
      <LottieImage options={{ animationData: PaymentRequestCustomerOne }} />
    </div>
  </div>
);

export default memo(ImageComponent);
