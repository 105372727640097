import { Typography } from "Components/Own";
import LottieImage from "Components/Own/LottieImage";
import { memo } from "react";
import { localesText } from "Utils/LocalesText.Util";
import Straight from "Assets/Lotties/home_straight.json";
import LowCode from "Assets/Lotties/home_low_code.json";
import AIDriven from "Assets/Lotties/home_ai.json";

const GetPaid = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-7 w-full">
      <div className="flex flex-col gap-3 md:gap-5 w-full">
        <div className="rounded-[20px] bg-clever-white-slate p-5 md:p-10 flex flex-col gap-3 md:gap-4 h-full">
          <Typography.Heading2>
            {localesText("home.straight_through")}
          </Typography.Heading2>

          <Typography.Description className="text-clever-gray">
            {localesText("home.all_payment")}
          </Typography.Description>
        </div>

        <LottieImage options={{ animationData: Straight }} />
      </div>

      <div className="flex flex-col-reverse lg:flex-col gap-3 md:gap-5 w-full">
        <LottieImage options={{ animationData: LowCode }} />

        <div className="rounded-[20px] bg-clever-white-slate p-5 md:p-10 flex flex-col gap-3 md:gap-4">
          <Typography.Heading2>
            {localesText("home.low_code")}
          </Typography.Heading2>

          <Typography.Description className="text-clever-gray">
            {localesText("home.integrate_our_payment")}
          </Typography.Description>
        </div>
      </div>

      <div className="flex flex-col gap-3 md:gap-5 w-full">
        <div className="rounded-[20px] bg-clever-white-slate p-5 md:p-10 flex flex-col gap-3 md:gap-4">
          <Typography.Heading2>
            {localesText("home.peace_of_mind")}
          </Typography.Heading2>

          <Typography.Description className="text-clever-gray">
            {localesText("home.with_advance_ai")}
          </Typography.Description>
        </div>

        <LottieImage options={{ animationData: AIDriven }} />
      </div>
    </div>
  );
};

export default memo(GetPaid);
