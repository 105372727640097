import { memo } from "react";

import { LottieImage } from "Components/Own";

import PaymentRequestCustomerTwo from "Assets/Lotties/payment_request_customer_two.json";

const Work = () => {
  return (
    <div className="flex flex-1 justify-center md:justify-end">
      <LottieImage options={{ animationData: PaymentRequestCustomerTwo }} />
    </div>
  );
};

export default memo(Work);
