import useSWRLib from "swr";

const useSWR = (url: string | null) => {
  const fetcher = (...args: any) => fetch(args).then((res) => res.json());

  const response = useSWRLib(url, fetcher);

  return { ...response };
};

export { useSWR };
