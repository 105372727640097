import { useState, useEffect, memo } from "react";
import { NormalImage } from "Components/Lib";
import Image1 from "Assets/Images/Others/about_us_one.png";
import Image2 from "Assets/Images/Others/about_us_two.png";
import Image3 from "Assets/Images/Others/about_us_three.png";
import Image4 from "Assets/Images/Others/about_us_four.png";

const MerchantDiscover = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = Math.max(0, window.scrollY);

      if (currentScrollTop > lastScrollTop) {
        setOffset(-100);
      } else if (currentScrollTop < lastScrollTop) {
        setOffset(0);
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="hidden xl:grid grid-cols-2 gap-1 absolute left-1 xl:left-10 2xl:left-20 top-1 transition-transform duration-500"
      style={{ transform: `translateX(${offset}px)` }}
    >
      <NormalImage
        src={Image1.src}
        alt="img"
        width={100}
        height={100}
        className="rounded-full"
      />
      <NormalImage
        src={Image2.src}
        alt="img"
        width={100}
        height={100}
        className="rounded-full"
      />
      <NormalImage
        src={Image3.src}
        alt="img"
        width={100}
        height={100}
        className="rounded-full"
      />
      <NormalImage
        src={Image4.src}
        alt="img"
        width={100}
        height={100}
        className="rounded-full"
      />
    </div>
  );
};

export default memo(MerchantDiscover);
