import { NormalImage } from "Components/Lib";
import ImgGroup from "Assets/Images/Others/about_us_group_two.webp";
import { useEffect, useState } from "react";

const ImageGroup1 = () => {
  const [offset, setOffset] = useState(0);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = Math.max(0, window.scrollY);

      if (currentScrollTop > lastScrollTop) {
        setOffset(isMobile ? 110 : 200);
      } else if (currentScrollTop < lastScrollTop) {
        setOffset(0);
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  return (
    <NormalImage
      style={{ transform: `translateY(${offset}px)` }}
      src={ImgGroup.src}
      alt="img"
      width={200}
      height={120}
      className="absolute left-5 sm:left-16 xl:left-32 bottom-[70px] sm:bottom-14 xl:bottom-24 transition-transform duration-500 w-[90px] h-[54px] md:w-[200px] md:h-[120px] object-cover rounded-xl md:rounded-2xl"
    />
  );
};

export default ImageGroup1;
