import { NormalImage } from "Components/Lib";
import ImgGroup from "Assets/Images/Others/about_us_single.png";
import Geometric from "Assets/Svg/geometric-icon.svg";
import { useEffect, useState } from "react";
import { classnames } from "Utils/Classnames.Util";

const ImageSingle = () => {
  const [offset, setOffset] = useState(0);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 1025;
  const isSmallLaptop =
    typeof window !== "undefined" && window.innerWidth < 1441;

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = Math.max(0, window.scrollY);

      if (currentScrollTop > lastScrollTop) {
        setOffset(isMobile ? 50 : isSmallLaptop ? 100 : 200);
      } else if (currentScrollTop < lastScrollTop) {
        setOffset(0);
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile, isSmallLaptop]);

  return (
    <div
      className="hidden xl:block absolute lg:right-20 2xl:right-40 bottom-10 transition-transform duration-500"
      style={{ transform: `translateX(${offset}px)` }}
    >
      <div className="relative">
        {offset < 200 && (
          <NormalImage
            src={Geometric.src}
            alt="geometric"
            width={154}
            height={154}
          />
        )}

        <NormalImage
          src={ImgGroup.src}
          alt="img"
          width={88}
          height={88}
          className={classnames(
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
            {
              "relative -bottom-16 left-7": offset === 200,
            },
          )}
        />
      </div>
    </div>
  );
};

export default ImageSingle;
