import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section6 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "py-[84px] md:py-[120px]",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-6",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
    </SectionWrap>
  );
};

export default Section6;
