import { SectionWrap } from "Components/Layouts";
import LaunchFasterComponent from "./LaunchFaster.Component";

const Section8 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "md:py-[120px]",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-6",
      }}
      className="flex items-center justify-center"
    >
      <LaunchFasterComponent />
    </SectionWrap>
  );
};

export default Section8;
