import React from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col items-center max-w-[870px] text-white text-center">
      <Typography.Giant className="mb-6">
        {localesText("payment_request.instant_payment")}
        <RotateHighlightText
          content={localesText("payment_request.your_way_title")}
          className="ml-3"
        />
      </Typography.Giant>

      <div>
        <Typography.Content className="inline lg:inline-block mr-1">
          {localesText("payment_request.fast_payment")}
        </Typography.Content>

        <Typography.Content className="inline lg:inline-block">
          {localesText("payment_request.customer_can_pay")}
        </Typography.Content>
      </div>
    </div>
  );
}
