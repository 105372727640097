import { memo, useEffect, useRef } from "react";
import ConfettiLeft from "Assets/Svg/confetti_left.svg";
import ConfettiRight from "Assets/Svg/confetti_right.svg";
import Image from "Components/Lib/Next/Image";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import confetti, { CreateTypes } from "canvas-confetti";

const randomInRange = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

const TitleComponent = () => {
  const duration = 5000;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
  const componentRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const confettiInstance = useRef<CreateTypes | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      confettiInstance.current = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true,
      });
    }

    const handleConfetti = () => {
      const animationEnd = Date.now() + duration;
      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();
        if (timeLeft <= 0) return clearInterval(interval);

        const particleCount = 80 * (timeLeft / duration);
        [0.2, 0.8].forEach((x) => {
          confettiInstance.current?.({
            ...defaults,
            particleCount,
            origin: {
              x: randomInRange(x - 0.1, x + 0.1),
              y: Math.random() - 0.2,
            },
          });
        });
      }, 250);
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleConfetti();
          observer.disconnect();
        }
      },
      { threshold: 1 },
    );

    if (componentRef.current) observer.observe(componentRef.current);

    return () => {
      observer.disconnect();
      confettiInstance.current?.reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={componentRef}
      className="relative flex flex-col lg:flex-row gap-10 lg:gap-6 w-screen min-h-[344px]"
    >
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full pointer-events-none"
      />

      <Image
        src={ConfettiLeft}
        alt="confetti-left"
        className="object-cover absolute z-0 bottom-20 left-0"
      />

      <Typography.SectionTitle className="text-clever-gray text-center z-10 mx-4 sm:mx-16 min-[1600px]:mx-[120px] md:leading-[72px]">
        {localesText("home.whether_you_are_shopping")}
        <span className="font-semibold ml-3 text-clever-black">
          {localesText("home.earn_cash_back")}
        </span>
        <img
          src="Assets/Svg/home_lightning_green.svg"
          alt="lightning"
          className="mx-1 inline"
          width={86}
          height={86}
        />
        <span className="mr-2">-</span>
        <span className="inline-block">{localesText("home.no_waiting")}</span>
        <span className="font-semibold text-clever-black ml-2 inline">
          {localesText("home.get_paid")}
        </span>
      </Typography.SectionTitle>

      <Image
        src={ConfettiRight}
        alt="confetti-right"
        className="object-cover absolute right-0 top-14"
      />
    </div>
  );
};

export default memo(TitleComponent);
