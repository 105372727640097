import { SectionWrap } from "Components/Layouts";

import GetPaidComponent from "./GetPaidList/GetPaid.Component";
import TitleComponent from "./Title.Component";

const Section4 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "py-[84px] md:py-[120px]",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-10 md:gap-[60px]",
      }}
    >
      <TitleComponent />
      <GetPaidComponent />
    </SectionWrap>
  );
};

export default Section4;
