import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import EmpowerComponent from "./Empower.Component";
import ImageGroup1 from "./ImageGroup1.Component";
import ImageGroup2 from "./ImageGroup2.Component";

const Section2 = () => {
  return (
    <SectionWrap
      containerProps={{
        className:
          "mt-14 md:mt-[150px] 2xl:mt-[300px] flex justify-center w-full relative",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center gap-5",
      }}
      className="flex items-center justify-center w-full"
    >
      <EmpowerComponent />
      <ImageGroup1 />
      <ImageGroup2 />
    </SectionWrap>
  );
};

export default memo(Section2);
