import { useScroll, useTransform, motion, MotionValue } from "framer-motion";
import React, { memo, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { useSmallMobileScreen } from "Hooks/useMediaQuery";

interface ParagraphProps {
  paragraph: string;
}

interface WordProps {
  children: string | React.JSX.Element;
  progress: MotionValue<number>;
  range: [number, number];
}

interface CharProps {
  children: string;
  progress: MotionValue<number>;
  range: [number, number];
}

const TitleComponent = ({ paragraph }: ParagraphProps) => {
  const container = useRef<HTMLParagraphElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const isMobile = useSmallMobileScreen();

  const { scrollYProgress } = useScroll({
    target: container,
    offset: !isMobile ? ["start 0.5", "start 0.1"] : ["start 0", "start 0"],
  });

  const { scrollYProgress: imageScroll } = useScroll({
    target: container,
    offset: ["start 0.5", "start 0.1"],
  });

  const width = useTransform(imageScroll, [0, 1], ["48px", "88px"]);

  const replaceStringToElement = (str: string): string | React.JSX.Element => {
    if (str.includes("{{logo}}")) {
      return (
        <img
          width={55}
          height={55}
          src="Assets/Svg/clever-logo-icon.svg"
          alt="logo"
          className="size-12 md:size-[55px]"
        />
      );
    }

    if (str.includes("{{groupImage}}")) {
      return (
        <motion.img
          ref={imageRef}
          style={{ width }}
          width={88}
          height={52}
          src="Assets/Images/Others/about_us_group.webp"
          alt="logo"
          className="h-[52px] inline object-cover rounded-lg cursor-pointer sm:hover:scale-[2.8] sm:hover:-rotate-[5deg] transition-transform duration-500 z-10 relative"
        />
      );
    }

    if (str.includes("{{moneyIcon}}")) {
      return (
        <img
          src="Assets/Svg/money_green.svg"
          alt="icon"
          className="inline max-[360px]:ml-2 min-[450px]:hidden"
        />
      );
    }

    if (str.includes("{{alike}}")) {
      return (
        <span className="rounded-full bg-[#E9BADC] sm:font-semibold py-2 px-5 inline">
          alike
        </span>
      );
    }

    return str; // Default case returns string
  };

  const words = (
    typeof paragraph === "string" ? paragraph.split(" ") : [paragraph]
  ).map(
    (word) => replaceStringToElement(word), // Replace string with element
  );

  return (
    <p ref={container} className={styles.paragraph}>
      {words.map((word, i) => {
        const start = i / words.length;
        const end = start + 1 / words.length;
        return (
          <Word key={i} progress={scrollYProgress} range={[start, end]}>
            {word}
          </Word>
        );
      })}
    </p>
  );
};

const Word = ({ children, progress, range }: WordProps) => {
  const amount = range[1] - range[0];
  const step = amount / (typeof children === "string" ? children.length : 1);
  const opacity = useTransform(progress, range, [0.2, 1]);

  if (typeof children === "string") {
    // If `children` is a string, split it into characters
    return (
      <span className={styles.word}>
        {children.split("").map((char, i) => {
          const start = range[0] + i * step;
          const end = range[0] + (i + 1) * step;
          return (
            <Char key={`c_${i}`} progress={progress} range={[start, end]}>
              {char}
            </Char>
          );
        })}
      </span>
    );
  }

  // If `children` is not a string (it's a JSX element), render it as is
  return (
    <motion.span
      style={{
        opacity,
      }}
    >
      {children}
    </motion.span>
  );
};

const Char = ({ children, progress, range }: CharProps) => {
  const opacity = useTransform(progress, range, [0, 1]);

  return (
    <span>
      <span className={styles.shadow}>{children}</span>
      <motion.span style={{ opacity }}>{children}</motion.span>
    </span>
  );
};

export default memo(TitleComponent);
