import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div>
      <Typography.SectionTitle className="text-center">
        <RotateHighlightText
          content={localesText("payment_request.how")}
          className="mr-2"
        />
        {localesText("payment_request.it_works")}
      </Typography.SectionTitle>
    </div>
  );
}
