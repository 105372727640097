import { memo } from "react";
import { Typography } from "Components/Own";
import Map from "Assets/Images/Others/map.png";
import Forbes from "Assets/Images/Others/forbes.png";
import WomenLeading from "Assets/Images/Others/women_leading.png";
import Fintech from "Assets/Images/Others/fintech.png";
import { NormalImage } from "Components/Lib";

const TitleComponent = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full h-full items-stretch gap-5 md:gap-6">
      <div className="bg-white-slate rounded-[40px] w-full">
        <div className="flex flex-col h-full">
          <div className="flex flex-col pt-5 px-3 sm:p-8 xl:p-16 gap-3 md:gap-6 pb-5 md:pb-[66px]">
            <Typography.Heading1 className="!text-2xl">
              Our global team
            </Typography.Heading1>

            <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-2 xl:gap-16 text-[#434A58]">
              <div className="flex flex-row items-center gap-2 xl:gap-3">
                <img src="Assets/Svg/group.svg" alt="icon" />
                <Typography.Body shouldScale>Founded 2021</Typography.Body>
              </div>

              <div className="flex flex-row items-center gap-2 xl:gap-3">
                <img src="Assets/Svg/building.svg" alt="icon" />
                <Typography.Body shouldScale>8 cities</Typography.Body>
              </div>

              <div className="flex flex-row items-center gap-2 xl:gap-3">
                <img src="Assets/Svg/countries.svg" alt="icon" />
                <Typography.Body shouldScale>6 countries</Typography.Body>
              </div>
            </div>
          </div>

          <NormalImage
            src={Map.src}
            alt="map"
            width={600}
            height={279}
            className="object-cover xl:pb-[29px] px-5 sm:px-0 sm:w-full"
          />
        </div>
      </div>

      <div className="bg-white-slate rounded-[40px] w-full">
        <div className="flex flex-col gap-3 md:gap-6 pt-5 px-3 sm:p-8 xl:pt-16 xl:px-16">
          <Typography.Heading1 className="!text-2xl">
            Awards
          </Typography.Heading1>

          <div className="flex flex-col gap-1 text-[#434A58] w-fit md:px-0">
            <Typography.Body shouldScale>
              🏆 2024 Forbes Asia 100 To Watch
            </Typography.Body>

            <Typography.Body shouldScale>
              🏆 2024 AFR Australia’s #2 Fastest Growing Startup
            </Typography.Body>

            <Typography.Body shouldScale>
              🏆 2023 Emerging Fintech of The Year Winner
            </Typography.Body>

            <Typography.Body shouldScale>
              🏆 2023 Women Leading Tech Award
            </Typography.Body>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4 pt-8 pb-[53px] px-3 lg:pb-0 sm:p-8 xl:px-16 2xl:px-16 justify-between">
          <NormalImage
            src={Forbes.src}
            alt="map"
            className="object-contain w-full h-full"
          />

          <NormalImage
            src="Assets/Svg/fast-starters.svg"
            alt="map"
            className="object-contain w-full h-full"
          />

          <NormalImage
            src={Fintech.src}
            alt="map"
            className="object-contain w-full h-full"
          />

          <NormalImage
            src={WomenLeading.src}
            alt="map"
            className="object-contain w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
