import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          <div className="max-w-[418px]">
            {localesText("payment_request.customise")}

            <div className="inline-block ml-2 md:ml-0">
              {localesText("payment_request.how_you")}
            </div>

            <RotateHighlightText
              content={localesText("payment_request.send")}
              className="mx-1 inline-block"
            />

            {localesText("payment_request.payments")}
          </div>
        </Typography.SectionTitle>
      </div>

      <div className="sm:pr-4 md:pr-11 text-clever-gray">
        <Typography.Body>
          {localesText("payment_request.payment_method")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
