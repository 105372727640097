import { NextLink } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { classnames } from "Utils/Classnames.Util";
import { localesText } from "Utils/LocalesText.Util";

export const SayHelloButton = () => {
  return (
    <NextLink
      href={PATHS_NAME.GET_IN_TOUCH}
      onClick={() => {
        logAnalyticEvent({ key: TRACKING_EVENTS.usingTypeformData });
      }}
    >
      <Button
        className={classnames(
          "mb-6 h-12 rounded-full border-none bg-clever-green px-7 text-sm text-black",
        )}
      >
        {localesText("common.say_hello")}
      </Button>
    </NextLink>
  );
};
