import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col gap-[24.45px]">
      <Typography.SectionTitle className="text-center">
        {localesText("common.easy")}

        <RotateHighlightText
          content={localesText("payment_request.payment")}
          className="inline-block mx-2"
        />

        {localesText("payment_request.collection")}
      </Typography.SectionTitle>

      <div className="text-center text-clever-gray max-w-[911px]">
        <Typography.Body className="inline sm:inline-block mr-1">
          {localesText("payment_request.designed_for")}
        </Typography.Body>

        <Typography.Body className="inline sm:inline-block">
          {localesText("payment_request.no_need")}
        </Typography.Body>
      </div>
    </div>
  );
}
