import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="xl:text-center">
      <Typography.SectionTitle className="inline-block">
        {localesText("home.get_paid_in")}
      </Typography.SectionTitle>

      <RotateHighlightText
        content={localesText("home.real_time")}
        className="!px-2 ml-1 text-[26px] leading-[36px] md:text-[56px] md:leading-[68px]"
      />

      <Typography.SectionTitle>
        {localesText("home.with_global")}
      </Typography.SectionTitle>
    </div>
  );
};

export default memo(TitleComponent);
