import { VentureSection } from "Components/Own";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

const AboutUs = () => {
  return (
    <div className="mt-10">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <VentureSection />
    </div>
  );
};

export default AboutUs;
