import { memo } from "react";
import Cashback from "Assets/Images/Icons/instant_cashback.png";
import { NormalImage } from "Components/Lib";
import { Typography } from "Components/Own";
import Marquee from "react-fast-marquee";

const EmpowerComponent = () => {
  const content = {
    icon: Cashback.src,
    text: "Powering prominent brands to achieve goals.",
  };

  return (
    <div className="relative w-screen bg-[#00F9FF] rotate-2 h-[70px] lg:h-[86px] flex items-center overflow-hidden z-20">
      <div className="absolute flex">
        <Marquee speed={100}>
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={`content-${index}`}
              className="flex items-center gap-2 lg:gap-[18px] mx-4 md:mx-8 flex-shrink-0"
            >
              <NormalImage
                src={content.icon}
                alt="icon"
                width={52}
                height={52}
                className="size-10 lg:size-[52px]"
              />
              <Typography.Heading3
                shouldScale={false}
                className="!text-xl lg:text-2xl font-semibold"
              >
                {content.text}
              </Typography.Heading3>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default memo(EmpowerComponent);
