import { classnames } from "Utils/Classnames.Util";

const SlateWhite = ({
  containerClassName,
  contentContainerClassName,
  firstComponent,
  secondComponent,
}: {
  containerClassName?: string | undefined;
  contentContainerClassName?: string | undefined;
  firstComponent: any;
  secondComponent: any;
}) => {
  return (
    <div
      className={classnames(
        "flex justify-center rounded-[20px] md:rounded-[40px] bg-white-slate",
        containerClassName,
      )}
    >
      <div className={classnames("gap-3", contentContainerClassName)}>
        {firstComponent}
        {secondComponent}
      </div>
    </div>
  );
};

export default SlateWhite;
