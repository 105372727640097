import { SectionWrap } from "Components/Layouts";

import TabComponent from "./Tab.Component";

const Section7 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-6",
      }}
    >
      <TabComponent />
    </SectionWrap>
  );
};

export default Section7;
