import { memo } from "react";

import { LottieImage } from "Components/Own";

import Create from "Assets/Lotties/in_store_create.json";
import Receive from "Assets/Lotties/in_store_receive.json";
import Share from "Assets/Lotties/in_store_share.json";

const Work = () => {
  return (
    <div className="flex flex-1 flex-col md:flex-row gap-5">
      <div className="relative w-full flex flex-col md:flex-row gap-5 pb-20 md:pb-[144.22px]">
        <LottieImage options={{ animationData: Create }} />
        <LottieImage options={{ animationData: Share }} />
        <LottieImage options={{ animationData: Receive }} />
      </div>
    </div>
  );
};

export default memo(Work);
