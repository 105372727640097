import { useState } from "react";
import BuyToEarn from "./BuyToEarn.Component";
import PayInComponent from "./PayIn.Component";
import PayOutComponent from "./PayOut.Component";
import OneStopComponent from "./OneStop.Component";
import classNames from "classnames";

const tabs = ["Buy to earn", "PayIn", "PayOut", "FX"] as const;
type Tab = (typeof tabs)[number];

const Tab = () => {
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  return (
    <div className="flex flex-col gap-10 md:gap-12 items-center justify-center w-full">
      <div className="bg-white-slate rounded-full p-1 flex gap-2 m-auto w-fit">
        {tabs.map((tab) => (
          <button
            key={tab}
            data-tab={tab}
            className={classNames(
              "px-4 md:px-8 py-2 rounded-full transition-all text-xs md:text-base duration-300 ease-in-out relative z-10",
              {
                "text-clever-link font-semibold bg-white": activeTab === tab,
                "text-clever-black font-semibold": activeTab !== tab,
              },
            )}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {activeTab === "Buy to earn" && <BuyToEarn />}
      {activeTab === "PayIn" && <PayInComponent />}
      {activeTab === "PayOut" && <PayOutComponent />}
      {activeTab === "FX" && <OneStopComponent />}
    </div>
  );
};

export default Tab;
